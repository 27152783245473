window.openModal = function(modalId) {
  modal = document.getElementById(modalId)
  modal.style.display = 'block'
}

window.closeModal = function(modalId) {
  modal = document.getElementById(modalId)
  modal.style.display = 'none'
}

window.copy = function(e) {
  let code = e.closest('.highlight-wrap').querySelector('.rouge-code')
  window.getSelection().selectAllChildren(code)
  navigator.clipboard.writeText(code.innerText)
    .then(() => e.innerText = 'Copied')
}

window.showFlashMessage = function(message, type='notice') {
  let flashMessage = document.getElementById('flashMessage')
  let flashMessageContent = document.createElement('div')

  if (type === 'alert') flashMessageContent.classList.add('flash-message-content--alert')
  flashMessageContent.classList.add('flash-message-content')
  flashMessageContent.innerText = message
  flashMessage.appendChild(flashMessageContent)

  setTimeout(() => {
    flashMessageContent.classList.add('flash-message-fade-out')
  }, 5000)
}
