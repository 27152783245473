window.addEventListener('scroll', function () {
  const scroll = document.documentElement.scrollTop;
  const scrolltopButton = document.getElementsByClassName('scrolltop')[0];

  if (!scrolltopButton) return

  if (scroll > 2000) {
    scrolltopButton.classList.add('scrolltop--active');
  } else {
    scrolltopButton.classList.remove('scrolltop--active');
  }

  scrolltopButton.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  });
});